const binding = { modules: {}, dataActions: {} };

    (binding.modules['sgi-theme'] = {
        c: () => require('partner/themes/sgi-theme/sgi-theme.tsx'),
        $type: 'themeModule',
        da: [],
        definitionExtensions: ['active-image','b2b-requests-status','business-organization-list','cart','content-block','header','image-list','navigation-menu','order-template-list','product-collection','promo-banner','ratings-histogram','reviews-list','search','sign-in','store-selector','text-block','tile-list','video-player','write-review'],
        iNM: false,
        ns: '__local__',
        n: 'sgi-theme',
        p: '__local__',
        
        pdp: '',
        
        themeSettings: 'sgi-theme.theme.settings.json',
        md: 'src/themes/sgi-theme'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };